import { createContext, useContext, type ReactNode } from "react";
import useId from "~/hooks/useId";
import { useLogger } from "./LoggerProvider";
import { useTeamAccount } from "./TeamAccountProvider";
import type { TeamContact } from "~/server/api/model/listContact";
import { api } from "~/server/trpc/react";

const teamContactContext = createContext<{
  teamContact: TeamContact;
  isLoading: boolean;
  updateContact: (teamContact: Partial<TeamContact>) => void;
  refetch: () => void;
}>({
  teamContact: null,
  isLoading: true,
  updateContact: () => {
    return null;
  },
  refetch: () => {
    return null;
  },
});

export const useTeamContact = () => {
  const context = useContext(teamContactContext);
  return {
    ...context,

    teamContact: context.teamContact as NonNullable<TeamContact>,
  };
};

export const TeamContactProvider = ({
  children,
  id,
  hasPlaceholder,
}: {
  children: ReactNode | ReactNode[];
  id?: string;
  hasPlaceholder?: boolean;
}) => {
  const currentId = useId("teamContactId", id);
  const logger = useLogger();
  const { activeTeamAccount } = useTeamAccount();
  const teamAccountId = activeTeamAccount?.id ?? "";
  const utils = api.useUtils();
  const { mutate: updateContact } = api.teamContact.update.useMutation({
    onSuccess: (data) => {
      utils.teamContact.get.setData(
        {
          teamAccountId: teamAccountId,
          id: currentId,
        },
        data
      );
    },
  });
  const {
    data: teamContact,
    isLoading,
    refetch,
  } = api.teamContact.get.useQuery({
    teamAccountId: teamAccountId,
    id: currentId,
  });
  const handleRefetch = () => {
    refetch().catch((err) => logger.error(err, "ContactProvider.tsx"));
  };
  const teamContactUpdater = (teamContact: Partial<TeamContact>) => {
    if (!teamContact?.id) throw new Error("statefulTeamContact is null");
    updateContact({
      ...teamContact,
      id: teamContact?.id,
      teamAccountId: teamAccountId,
    });
  };
  if (!teamContact) {
    if (hasPlaceholder) {
      return children;
    }

    return <></>;
  } else {
    return (
      <teamContactContext.Provider
        value={{
          teamContact: teamContact as TeamContact,
          updateContact: teamContactUpdater,
          refetch: handleRefetch,
          isLoading,
        }}
      >
        {children}
      </teamContactContext.Provider>
    );
  }
};
