import { useParams } from "next/navigation";
import { useMemo, useRef, useState } from "react";
import { useLists } from "~/providers/ListsProvider";
import { useTeamAccount } from "~/providers/TeamAccountProvider";
import { useTracker } from "~/providers/TrackerProvider";
import type {
  ComprehensiveContactData,
  Contact,
} from "~/server/api/model/listContact";

import { api } from "~/server/trpc/react";

const useComprehensiveContactData = (
  type: "repo" | "repo-dependencies" | "user" | "user-dependencies",
  contact: Contact
): ComprehensiveContactData | null => {
  const githubContactId = contact?.githubContactId as string;
  const tracker = useTracker();
  const params = useParams();
  const { listId } = params as { listId: string };
  const { lists } = useLists();
  const { activeTeamAccount } = useTeamAccount();

  const [hasLoadedSomeEvals, setHasLoadedSomeEvals] = useState<boolean>(false);

  const { data: evaluations } =
    api.evaluation.evaluationsByTypeAndContactId.useQuery({
      listId,
      type,
      contactId: githubContactId,
      teamAccountId: activeTeamAccount?.id,
    });

  const { data: dependencyEvaluations } =
    api.evaluation.evaluationsByTypeAndContactId.useQuery({
      listId,
      type: type.concat("-dependencies") as typeof type,
      contactId: githubContactId,
      teamAccountId: activeTeamAccount?.id,
      trackerName: tracker?.trackerName,
    });

  const currentContactMap = useRef<ComprehensiveContactData | null>(null);

  return useMemo(() => {
    const evaluation =
      evaluations?.find((evaluation) => {
        return evaluation.githubContactId === contact?.githubContactId;
      }) ?? null;
    const depsEvaluation =
      dependencyEvaluations?.find((evaluation) => {
        return evaluation.githubContactId === contact?.githubContactId;
      }) ?? null;

    const list =
      lists.find((list) => list.id === depsEvaluation?.listId) || lists[0];

    const result: ComprehensiveContactData = {
      githubEvaluation: evaluation,
      githubDepsEvaluation: depsEvaluation,
      list,
      contact: contact as NonNullable<Contact>,
      tracker: tracker ?? null,
    };
    const firstResult = result;
    const hasFirstResultLoadedEvals = firstResult?.githubEvaluation;
    if (!hasLoadedSomeEvals) {
      if (hasFirstResultLoadedEvals) setHasLoadedSomeEvals(true);

      currentContactMap.current = result;
      return result;
    }
    if (hasLoadedSomeEvals) {
      if (firstResult?.githubEvaluation) {
        currentContactMap.current = result;
        return result;
      }
    }
    return currentContactMap.current;
  }, [
    lists,
    evaluations,
    contact,
    dependencyEvaluations,
    hasLoadedSomeEvals,
    tracker,
  ]);
};
export default useComprehensiveContactData;
