import { useContext, useState } from "react";
import LineHeader from "~/components/email/Composer/ComposerUI/LineHeader";
import ComposerContext from "../ComposerStore/ComposerContext";
import type { ComposerContextComplete } from "../ComposerStore/ComposerContextType";

const ComposeHeaders = () => {
  const [showCC, setShowCC] = useState(false);
  const [showBCC, setShowBCC] = useState(false);
  const context = useContext<ComposerContextComplete>(ComposerContext);
  const { state, dispatch } = context;
  const { composeType } = state;
  const handleUpdateTo = (to: string[]) => {
    dispatch({ type: "UPDATE_TO", payload: to });
  };
  const handleUpdateFrom = (from: string[]) => {
    dispatch({ type: "UPDATE_FROM", payload: from });
  };
  const handleUpdateCC = (cc: string[]) => {
    dispatch({ type: "UPDATE_CC", payload: cc });
  };
  const handleUpdateBCC = (bcc: string[]) => {
    dispatch({ type: "UPDATE_BCC", payload: bcc });
  };
  return (
    <>
      {composeType === "Email" && (
        <div className="border-x border-b px-8 py-2 ">
          <LineHeader
            dispatchCreator={handleUpdateTo}
            parentValue={state.to}
            title={"To"}
            allowMultiple={true}
          />
          {showBCC && (
            <LineHeader
              dispatchCreator={handleUpdateBCC}
              parentValue={state.bcc}
              title={"BCC"}
              allowMultiple={true}
              setClosed={() => setShowBCC(false)}
            />
          )}
          {showCC && (
            <LineHeader
              dispatchCreator={handleUpdateCC}
              allowMultiple={true}
              parentValue={state.cc}
              title={"CC"}
              setClosed={() => setShowCC(false)}
            />
          )}
          <div className="-my-2 flex justify-between">
            <LineHeader
              dispatchCreator={handleUpdateFrom}
              parentValue={state.from}
              title={"From"}
            />
            <div className="flex h-min gap-2 self-center  font-bold text-link hover:opacity-75">
              {composeType === "Email" && (
                <>
                  <button onClick={() => setShowCC(true)} className="px-2 py-0">
                    Cc
                  </button>
                  <button
                    onClick={() => setShowBCC(true)}
                    className="px-2 py-0"
                  >
                    Bcc
                  </button>
                </>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ComposeHeaders;
