export default function LoadingBar({
  completed,
}: Readonly<{ completed: number }>) {
  return (
    <div className="flex items-center justify-between space-x-4">
      <div className="h-2 w-[95%] rounded-full bg-blue-200">
        <div
          className={`w-[${completed.toString()}%] bg-link ${
            completed === 100 ? "" : "animate-pulse"
          } h-2 rounded-full duration-300 ease-in-out`}
          style={{ width: `${completed}%` }}
        />
      </div>
      <div>{completed.toFixed(1)}%</div>
    </div>
  );
}
